<template>
  <section class="tag-management-wrap" v-loading="loading">
    <!-- <section class="filter">
      <el-form :inline="true" :model="form">
        <el-form-item label="关键词">
          <el-input v-model="form.keyWords" placeholder="搜索规则名称、通知标题"></el-input>
        </el-form-item>
        <el-form-item class="btn-item">
          <el-button type="primary" @click="onSubmit">查询</el-button>
        </el-form-item>
      </el-form>
    </section> -->
    <section class="content">
      <div class="btn-wrap">
        <el-button size="small" type="primary" @click="dialogVisible = true">创建</el-button>
      </div>
      <el-table
          :data="tableData"
          border max-height="calc(100% - 40px)"
          style="width: 100%">
          <el-table-column  :resizable="false"
            prop="markName"
            label="标签名称"
            align="center">
          </el-table-column>
          <el-table-column  :resizable="false"
            prop="num"
            label="数据数"
            align="center">
          </el-table-column>
<!--          <el-table-column  :resizable="false"-->
<!--            prop="creator"-->
<!--            align="center"-->
<!--            label="创建者">-->
<!--          </el-table-column>-->
          <el-table-column  :resizable="false"
            prop="createDate"
            align="center"
            label="创建时间">
          </el-table-column>
          <el-table-column  :resizable="false"
            align="center"
            label="操作">
            <template scope="{ row }">
              <el-button type="text" style="color:#3472c7" @click="alterMarkGroupDialog(row)">修改</el-button>
              <el-button type="text" style="color:#3472c7" @click="deleteMarkGroupDialog(row)">删除</el-button>
            </template>
          </el-table-column>
      </el-table>
      <section class="list-bottom">
          <span class="count">共{{count}}条记录</span>
          <el-pagination
            :current-page="currentPage"
            :page-sizes="[ 10 , 20, 30, 50]"
            :page-size="10"
            layout="total, sizes, prev, pager, next, jumper"
            :total="count"
            @size-change="sizeChange"
            @current-change="currentChange">
          </el-pagination>
        </section>
    </section>
    <el-dialog
      title="创建"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose">
      <el-form label-position="top" label-width="80px" :model="addForm" ref="addForm" :rules="rules">
        <el-form-item label="标签" prop="markName">
          <el-input v-model="addForm.markName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelMarkGroup">取 消</el-button>
        <el-button type="primary" @click="viewsaveMark">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="修改"
      :visible.sync="alterMarkGroupFlag"
      width="30%">
      <el-form label-position="top" label-width="80px" :model="alterForm" ref="alterForm" :rules="rules">
        <el-form-item label="标签名:" prop="markName">
          <el-input v-model="alterForm.markName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelAlterMarkGroup">取 消</el-button>
        <el-button type="primary" @click="updateMark">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="deleteMarkGroupFlag"
      width="30%"
      center>
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancelDeleteMarkGroup">取 消</el-button>
        <el-button type="primary" @click="confirmDeleteMark">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
import { initTag, saveMark, markById, updateMark, delectMark, listMarksById } from '@/api/settings/tag'
export default {
  data () {
    return {
      loading: true,
      form: {
        keyWords: ''
      },
      tableData: [],
      deleteMarkGroupId: '',
      dialogVisible: false,
      alterMarkGroupFlag: false,
      deleteMarkGroupFlag: false,
      addForm: {
        markName: ''
      },
      rules: {
        markName: [{ required: true, message: '请输入主题名称', trigger: 'blur' }]
      },
      alterForm: {
        id: '', // 待修改的标签组id
        markName: '' // 新的标签组名称
      },
      limit: 10,
      currentPage: 1,
      count: 0,
      ids: []
    }
  },
  methods: {
    async selectAllTagByid () {
      try {
        const res = await listMarksById({ groupId: this.$route.params.id,pageSize: this.limit, current: this.currentPage })
        this.tableData = res.data
        this.count = res.count
        console.log(res.msg)
      } catch (err) {
        console.log(err)
      }
      this.loading = false
    },
    // 取消删除
    cancelDeleteMarkGroup () {
      this.deleteMarkGroupFlag = false
    },
    // 确定删除
    async confirmDeleteMark () {
      try {
        const res = await delectMark({ ids: this.ids })
        if (res.code === 0) {
          this.$message.success('删除成功')
        } else {
          this.$message.error('删除失败')
        }
      } catch (err) {
        this.$message.error('删除失败')
        console.log(err)
      }
      this.currentPage = 1
      this.form.keyWords = ''
      this.selectAllTagByid()
      this.deleteMarkGroupFlag = false
    },
    // 显示-删除标签组弹窗
    deleteMarkGroupDialog (row) {
      this.ids.push(row.id)
      this.deleteMarkGroupFlag = true
    },
    // 取消修改标签组名称
    cancelAlterMarkGroup () {
      this.alterMarkGroupFlag = false
      this.$refs.alterForm.resetFields()
    },
    // 更新标签组名称
    async updateMark () {
      this.$refs['alterForm'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await updateMark({ id: this.alterForm.id, markName: this.alterForm.markName, groupId: this.$route.params.id })
            if (res.code === 0) {
              this.form.keyWords = ''
              this.selectAllTagByid()
              this.alterMarkGroupFlag = false
              this.$refs.alterForm.resetFields()
              this.$message.success('修改成功')
            } else {
              this.$message.error(res.msg)
            }
          } catch (err) {
            this.$message.error('修改失败')
            console.log(err)
          }

        } else {
          console.log('error submit!!')
          return false
        }

      });
    },
    // 显示-修改标签组的弹窗
    async alterMarkGroupDialog (row) {
      this.alterMarkGroupFlag = true
      this.alterForm.id = row.id
      const res = await markById({ id: this.alterForm.id })
      this.alterForm.markName = res.data.markName
    },
    // 初始化数据的公共方法
    async dataInit () {
      try {
        const res = await initTag({ pageSize: this.limit, current: this.currentPage, groupName: this.form.keyWords })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    },
    // 取消保存
    cancelMarkGroup () {
      this.dialogVisible = false
      this.$refs.addForm.resetFields()
    },
    // 保存新增的标签组
    async viewsaveMark () {
      this.$refs['addForm'].validate(async (valid) => {
        if (valid) {
          try {
            const res = await saveMark({ markName: this.addForm.markName, groupId: this.$route.params.id })
            if (res.code === 0) {
              this.selectAllTagByid()
              this.dialogVisible = false
              this.$refs.addForm.resetFields()
              this.$message.success('保存成功')
            } else {
              this.$message.error(res.msg)
            }
          } catch (err) {
            this.$message.error('保存失败')
            console.log(err)
          }

        } else {
          console.log('error submit!!')
          return false
        }
      });

    },
    handleClose () {
      this.dialogVisible = false
    },
    async sizeChange (i) {
      this.limit = i
      this.selectAllTagByid()
    },
    async currentChange (i) {
      this.currentPage = i
      this.selectAllTagByid()
    },
    async onSubmit () {
      this.currentPage = 1
      try {
        const res = await initTag({ pageSize: this.limit, current: this.currentPage, groupName: this.form.keyWords })
        this.tableData = res.data
        this.count = res.count
      } catch (err) {
        console.log(err)
      }
    }
  },
  async mounted () {
    this.selectAllTagByid()
    eventHub.$emit('msg', '标签管理')
  }
}
</script>

<style lang="scss" scoped="">
.tag-management-wrap {
  width: 100%;
  height: 100%;
  .filter {
    width: 100%;
    height: 80px;
    padding: 20px;
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    margin-bottom: 30px;
    .btn-item {
      margin-left: 25px;
    }
  }
  .content {
    width: 100%;
    min-height: calc(100% - 110px);
    background: #fff;
    box-shadow:0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    border-radius:2px;
    padding: 20px;
    position: relative;
    .btn-wrap {
      margin-bottom: 25px;
    }
    .el-table {
      margin-bottom: 50px;
      .el-button--text {
        padding: 0;
      }
    }
    .list-bottom {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;
      .count {
        line-height: 32px;
      }
    }
  }
  .el-dialog__wrapper {
    .el-dialog__body {
      padding: 0px 20px;
    }
  }
}
</style>
